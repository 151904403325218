/*====================================
=            GRID GLOBALS            =
====================================*/

$grid-columns:12;
$gutter:30px;

/*=====  End of GRID GLOBALS  ======*/


/*==================================
=            ANIMATIONS            =
==================================*/

$transition1: all .3s cubic-bezier(0.3, 0.0, 0.2, 1);
$transition2: all .45s cubic-bezier(0.45, 0.0, 0.2, 1);

/*=====  End of ANIMATIONS  ======*/


/*=================================
=            STRUCTURE            =
=================================*/

$mainArticleHeight:410px;
$smallArticleHeight:190px;
$mediumArticleHeight:210px;
$programColHeight:208px;
$staffHeight:284px;

/*=====  End of STRUCTURE  ======*/


/*=============================
=            COLOR            =
=============================*/

$accent: #404484;
$accentLight: #4e53a3;
$dark:#2a2a2a;
$dark2:#3d3d3d;

/*=====  End of COLOR  ======*/
