//MOBILE > 768
@include mobile {
    .top_header {
        padding-left: 0;
        padding-right: 0;
        min-height: 75px;
        .top_header_right {
            float: left;
            width: 100%;
            margin-top: 10px;
        }
    }
    .has-scrolled {
        transform: translateY(-75px) !important;
    }
    .bottom_header {
        padding: 10px 0;
        height: auto;
        background: white;
    }
    .mainWrap {
        margin-top: 146px;
    }
    .current-menu-item {
        border-bottom: 0 !important;
    }
    .logo {
        max-width: 80px;
    }
    .nav_wrap {
        position: fixed;
        background: white;
        height: calc(100vh - 56px);
        top: 130px;
        z-index: 222;
        left: 0;
        width: 100%;
        padding: 0 20px;
        will-change: transform;
        transform: translateX(-100%);
        transition: $transition1;
        .nav-active & {
            transform: translateX(0);
        }
        .main_menu {
            margin-left: 0;
            text-align: center;
            .has-children:after {
                display: none;
            }
            li {
                width: 100%;
                text-align: center;
                padding-bottom: 4px;
                margin-bottom: 20px;
                margin-right: 0;
                .sub-menu {
                    width: 100%;
                    opacity: 1;
                    visibility: visible;
                    display: block;
                    position: static;
                    padding-top: 14px;
                    background: transparent;
                    transform: translate(0) !important;
                    li {
                        margin-bottom: 0 !important;
                    }
                    a {
                        color: $accent;
                        font-size: 12px;
                        padding: 5px 0;
                    }
                }
            }
        }
        .header_search {
            width: 100%;
        }
    }
    .nav_toggle {
        float: right;
        width: 30px;
        height: 22px;
        position: relative;
        transform: rotate(0deg);
        transition: $transition1;
        margin-top: 6px;
        cursor: pointer;
        span {
            display: block;
            float: left;
            height: 4px;
            width: 100%;
            background: $accent;
            border-radius: 3px;
            opacity: 1;
            left: 0;
            margin-bottom: 5px;
            transform: rotate(0deg);
            transition: $transition1;
            transform-origin: left center;
        }
        &.is-active span:nth-child(1) {
            transform: rotate(45deg) translate(5px, -5px);
        }
        &.is-active span:nth-child(2) {
            opacity: 0;
        }
        &.is-active span:nth-child(3) {
            transform: rotate(-45deg) translate(3px, 7px);
        }
    }
    //END HEADER
    // ## HOME
    .main_article {
        margin-bottom: 30px;
    }
    .content-thumb {
        padding: 15px !important;
        h2 {
            font-size: 25px !important;
        }
    }
    .small_article_content {
        padding: 15px;
    }
    .program_col {
        padding-top: 15px;
        padding-bottom: 15px;
    }
    .program_col_icon {
        width: 100%;
        text-align: center;
        margin-left: 0 !important;
        transform: translateX(0) !important;
    }
    .full-program-col .arrow_icon {
        right: 10px;
        bottom: 10px;
    }
    .program_col_content {
        width: 100% !important;
        padding: 15px !important;
    }
    .section_headline {
        margin-left: 0 !important;
    }
    .medium_article,
    .aktivitet_col .medium_article {
        margin-bottom: 30px;
    }
    .medium_col:last-child .medium_article {
        margin-bottom: 0;
    }
    .donations_row {
        flex-wrap: wrap;
    }
    //##About
    .about_thumb {
        margin-bottom: 30px;
        height: 200px;
    }
    .staff_item {
        margin-bottom: 30px;
    }
    .mission_content {
        margin-bottom: 30px;
        padding: 20px 15px;
    }
    .mission_content h2,
    .mission_content h3,
    .role_item h2,
    .role_item h3,
    .value_item h2,
    .value_item h3 {
        font-size: 25px;
    }
    .about_content h1,
    .programet_content h1 {
        font-size: 25px;
    }
    .role_item h3,
    .value_item h3 {
        font-size: 18px;
    }
    //## Programet
    .programet_slider {
        height: 200px;
        margin-bottom: 30px;
        img {
            height: 200px;
        }
    }
    .program_col_item {
        width: 100%;
        margin-bottom: 30px;
        padding-left: 0;
        .number {
            margin-bottom: 0;
            width: 45px;
            height: 45px;
        }
        &_content {
            padding-top: 0;
            width: calc(100% - 45px);
            padding-left: 15px;
            padding-right: 0;
            margin-top: 0;
            &:before{
            	display: none;
            }
        }
    }
    .purple_line {
        display: none;
    }
    //##Publikimet
    .radio_group,
    .select_filter {
        width: 100%;
        margin-top: 0;
    }
    .custom_radio_wrap {
        margin-bottom: 10px;
        font-size: 14px;
        &:before {
            top: 5px;
            width: 13px;
            margin: 0 10px;
        }
    }
    .select_filter {
        margin-top: 10px;
        .input_group {
            width: 50%;
            select {
                width: 100%;
            }
        }
        .input_group:first-child {
            margin-right: 15px;
        }
    }
    //##Contact
    .contact_section {
        .column-mob-12:last-child .contact_item {
            margin-bottom: 0;
        }
    }
    .contact_item {
        padding: 20px 15px;
        margin-bottom: 30px;
        h2 {
            margin-bottom: 40px;
        }
    }
    .contact_form {
        margin-bottom: 30px;
        input {
            width: 100%;
            max-width: 100%;
        }
        span {
            margin-top: -10px;
            margin-bottom: 20px;
        }
    }
    //## Lajme
    .news_item {
        &_thumb {
            margin-bottom: 20px;
        }
        &_content {
            h3 {
                font-size: 23px;
            }
            p {
                width: 100%;
                float: left;
            }
            .date_time {
                float: left;
                width: auto;
            }
            .news_item_date {
                float: left;
                font-size: 12px;
                width: auto;
                margin-right: 15px;
            }
        }
    }
    //## Single
    .article_thumb {
        height: 200px;
    }
    .share_article {
        margin-top: 20px;
        margin-bottom: 60px;
    }
    .article_content {
        h1 {
            font-size: 24px;
        }
        ul,
        ol {
            padding-left: 20px;
        }
        blockquote,
        q {
            padding-left: 21px;
            padding-right: 21px;
            &:before,
            &:after {
                font-size: 60px;
            }
            &:after {
                bottom: -44px;
            }
        }
    }
    .news_article .container {
        padding-bottom: 30px;
    }
    //##FOOTER
    .main_footer {
        padding: 30px 0;
    }
    .wide_footer_content {
        margin-bottom: 30px;
    }
    .mid_footer_content {
        padding-left: 0 !important;
        margin-bottom: 30px;
    }
    .lajme_filter {
        form {
            width: 100%;
            display: flex;
        }
    }
}

//Tablets 768 - 990
@include tablet {
    .main_header {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 222;
        transition: $transition1;
    }
    .bottom_header {
        padding: 10px 0;
        height: auto;
        background: white;
        width: 100%;
    }
    .mainWrap {
        margin-top: 141px;
    }
    .logo {
        max-width: 80px;
    }
    .main_menu nav>ul>li.current-menu-item {
        border-color: transparent;
    }
    .nav_wrap {
        position: fixed;
        background: white;
        height: calc(100vh - 56px);
        top: 110px;
        z-index: 222;
        left: 0;
        width: 100%;
        padding: 0 20px;
        will-change: transform;
        transform: translateX(-100%);
        transition: $transition1;
        .nav-active & {
            transform: translateX(0);
        }
        .main_menu {
            margin-left: 0;
            text-align: center;
            .has-children:after {
                display: none;
            }
            li {
                width: 100%;
                text-align: center;
                padding-bottom: 4px;
                margin-bottom: 20px;
                margin-right: 0;
                .sub-menu {
                    width: 100%;
                    opacity: 1;
                    visibility: visible;
                    display: block;
                    position: static;
                    padding-top: 14px;
                    background: transparent;
                    transform: translate(0) !important;
                    li {
                        margin-bottom: 0 !important;
                    }
                    a {
                        color: $accent;
                        font-size: 12px;
                        padding: 5px 0;
                    }
                }
            }
        }
        .header_search {
            width: 100%;
        }
    }
    .nav_toggle {
        float: right;
        width: 30px;
        height: 22px;
        position: relative;
        transform: rotate(0deg);
        transition: $transition1;
        margin-top: 12px;
        cursor: pointer;
        span {
            display: block;
            float: left;
            height: 4px;
            width: 100%;
            background: $accent;
            border-radius: 3px;
            opacity: 1;
            left: 0;
            margin-bottom: 5px;
            transform: rotate(0deg);
            transition: $transition1;
            transform-origin: left center;
        }
        &.is-active span:nth-child(1) {
            transform: rotate(45deg) translate(5px, -5px);
        }
        &.is-active span:nth-child(2) {
            opacity: 0;
        }
        &.is-active span:nth-child(3) {
            transform: rotate(-45deg) translate(3px, 7px);
        }
    }
    //END HEADER
    .logo {
        float: left;
        max-width: 100px;
        margin-top: 7px;
    }
    .main_menu {
        margin-left: 20px;
        ul> {
            >li {
                margin-right: 20px !important;
            }
        }
    }
    //##Home
    .content-thumb {
        padding-left: 30px !important;
    }
    .small_article_content {
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 14px;
    }
    .program_col {
        &:hover {
            .program_col_icon {
                transform: translateX(0) !important;
            }
        }
    }
    .full-program-col .program_col_content {
        padding-top: 0;
    }
    .section_headline {
        margin-left: 0 !important;
    }
    .about_thumb,
    .programet_slider_wrap {
        height: 310px;
        margin-bottom: 30px;
        img {
            height: 310px;
        }
    }
    .program_col_icon {
        width: 108px;
        height: 108px;
    }
    .program_col_content {
        padding-left: 30px;
    }
    //Staff
    .staff_item {
        margin-bottom: 30px;
    }
    .mission_content,
    .role_item,
    .value_item {
        padding: 20px;
    }
    .role_item h3,
    .value_item h3 {
        word-break: break-word;
    }
    .select_filter {
        float: left;
        width: 100%;
        margin-top: 20px;
    }
    .contact_item {
        padding: 30px;
        h2,
        p {
            width: 100%;
            float: left;
        }
        h2 {
            margin-bottom: 40px;
        }
    }
    .contact_form {
        span {
            margin-top: -10px;
            margin-bottom: 20px;
        }
        input {
            width: 100%;
            max-width: 100%;
        }
        .input_group {
            width: 100%;
            max-width: 100%;
        }
    }
    .news_item_date {
        float: left;
        font-size: 12px;
        margin-right: 15px;
    }
    .news_item {
        .date_time {
            float: left;
            width: auto;
        }
        p {
            float: left;
            width: 100%;
            margin-top: 5px;
        }
    }
}

//Big Tablets
@include tablet-big {
    .main_menu {
        margin-left: 30px;
        nav>ul {
            >li {
                margin-right: 30px !important;
                &:last-child {
                    margin-right: 0 !important;
                }
            }
        }
    }
    .section_headline {
        margin-left: 0 !important;
    }
    .contact_item p {
        float: left;
        width: 100%;
        margin-top: 40px;
    }
    .mid_footer_content {
        padding-left: 0 !important;
    }
}
