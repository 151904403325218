//Footer
.main_footer {
    background: #44488d;
    padding-top: 55px;
    padding-bottom: 63px;
    margin-top: 64px;
    li {
        font-size: 12px;
        color: white;
        line-height: 1.5em;
        letter-spacing: 0.02em;
    }
    a {
        color: white;
        text-decoration: none;
    }
    span {
        font-size: 14px;
        color: white;
    }
}

.wide_footer_content {
    @extend %fullBlock;
    p {
        @extend %fullBlock;
        font-size: 10px;
        color: white;
        letter-spacing: 0.02em;
        margin-top: 12px;
    }
}

.second_footer_col {
    padding-left: 50px;
}

.third_footer_col {
    padding-left: 33px;
}

.social_footer {
    @extend %fullBlock;
    text-align: center;
    margin-top: 24px;
    li {
        margin-right: 47px;
        display: inline-block;
        font-size: 22px;
        &:last-child {
            margin-right: 0;
        }
    }
}
