/*============================
=            HOME            =
============================*/

.mainWrap {
    margin-top: 176px;
}

.main_article {
    float: left;
    width: 100%;
    position: relative;
    height: $mainArticleHeight;
    margin-bottom: $gutter;
    &:hover {
        .overlay {
            opacity: 0;
        }
    }
    .overlay {
        @extend %overlay;
        float: left;
        background: transparentize($accent, 0.8);
        transition: $transition1;
    }
    img {
        @extend %imgCropped;
    }
    .content-thumb {
        float: left;
        width: 100%;
        padding-left: 60px;
        padding-bottom: 50px;
        padding-right: 40px;
        position: absolute;
        bottom: 0;
        left: 0;
        span.date-time {
            font-size: 14px;
            color: #fff;
            float: left;
            padding-bottom: 16px;
            border-bottom: 4px solid $accent;
        }
        h2 {
            float: left;
            width: 100%;
            font-weight: 900;
            font-size: 28px;
            color: #fff;
            margin-top: 14px;
            line-height: 1.2em;
            text-transform: uppercase;
        }
    }
}

//Small Article
.small_article {
    @extend %fullBlock;
    height: $smallArticleHeight;
    margin-bottom: $gutter;
    position: relative;
    .date-time {
        font-size: 12px;
    }
    &:before {
        content: " ";
        @extend %overlay;
        background: transparentize($accent, 0.7);
        z-index: 1;
        transition: $transition1;
    }
    img {
        width: 100%;
        height: $smallArticleHeight;
        object-fit: cover;
        filter: grayscale(100%);
        transition: $transition1;
    }
    &:hover {
        &:before {
            opacity: 0;
        }
        img {
            filter: grayscale(0);
        }
    }
    &_content {
        @extend %fullBlock;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 2;
        color: white;
        text-transform: uppercase;
        padding-left: 30px;
        padding-right: 20px;
        padding-bottom: ($gutter - 5px);
        h3 {
            font-weight: 900;
            letter-spacing: 0.02em;
            line-height: 1.313em;
            margin-top: 17px;
            overflow: hidden;
            max-height: 110px;
        }
    }
}

//Program Columns
.program_col {
    @extend %fullBlock;
    padding: #{$gutter} 0 #{($gutter + 10px)} 0;
    overflow: hidden;
    background-image: -moz-linear-gradient( 80deg, rgb(64, 68, 133) 0%, rgb(48, 141, 227) 100%);
    background-image: -webkit-linear-gradient( 80deg, rgb(64, 68, 133) 0%, rgb(48, 141, 227) 100%);
    background-image: -ms-linear-gradient( 80deg, rgb(64, 68, 133) 0%, rgb(48, 141, 227) 100%);
    background-color: #3f488a;
    margin-bottom: 30px;
    &:hover {
        .program_col_icon {
            transform: translateX(32px);
        }
    }
    &_icon {
        float: left;
        width: 138px;
        height: 138px;
        margin-left: -11px;
        transition: $transition1;
    }
    &_content {
        float: left;
        width: calc(100% - 127px);
        padding-left: 54px;
        color: white;
        padding-right: 30px;
        padding-top: 5px;
        h3 {
            @extend %fullBlock;
            text-transform: uppercase;
            font-size: 28px;
            letter-spacing: 0.02em;
            font-weight: 900;
            line-height: 1.179em;
            margin-bottom: 20px;
        }
        p {
            font-size: 13px;
            line-height: 1.2em;
        }
    }
}

.education-col {
    background-image: -moz-linear-gradient( -74deg, rgb(64, 68, 133) 0%, rgb(134, 74, 202) 100%);
    background-image: -webkit-linear-gradient( -74deg, rgb(64, 68, 133) 0%, rgb(134, 74, 202) 100%);
    background-image: -ms-linear-gradient( -74deg, rgb(64, 68, 133) 0%, rgb(134, 74, 202) 100%);
    background-color: #434588;
}

.full-program-col {
    padding-top: 18px;
    padding-bottom: 20px;
    background-image: url('../images/full_width_program_col_bg.png');
    background-color: #2a2a2a;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    .arrow_icon {
        position: absolute;
        right: $gutter;
        bottom: $gutter;
        backface-visibility: hidden;
    }
    .program_col_icon {
        height: 120px;
        margin-left: -7px;
    }
    .program_col_content {
        width: calc(100% - 131px);
        text-transform: uppercase;
        padding-top: 24px;
        h3 {
            margin-bottom: 13px;
        }
        p {
            font-size: 18px;
            letter-spacing: 0.02em;
        }
    }
}

//Lajme Section
.block_section {
    @extend %fullBlock;
    clear: both;
}

.lajme_section {
    margin-bottom: 48px;
}

//Section Headline
.section_headline {
    clear: both;
    overflow: hidden;
    margin-bottom: 29px;
    margin-left: (-($gutter/2));
    margin-top: 4px;
    h2,
    h1 {
        @extend %fullBlock;
        color: #4b4b4b;
        letter-spacing: 0.02em;
        position: relative;
        clear: both;
        overflow: hidden;
        font-size: 12px;
        color: $dark;
        font-weight: 900;
        text-transform: capitalize;
        span {
            background: white;
            position: relative;
            z-index: 2;
            padding-right: $gutter;
        }
        &:before {
            content: " ";
            position: absolute;
            height: 1px;
            width: 100%;
            top: 50%;
            -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
            margin-top: 1px;
            background: #d7d7d7;
            z-index: 1;
        }
    }
}

//Medium Article
.medium_article {
    @extend %fullBlock;
    &_thumb {
        @extend %fullBlock;
        height: $mediumArticleHeight;
        margin-bottom: 18px;
        position: relative;
        &:after {
            content: " ";
            @extend %overlay;
            background: transparentize($accent, .7);
            transition: $transition1;
        }
        img {
            @extend %imgCropped;
            filter: grayscale(100%);
            transition: $transition1;
        }
    }
    &_content {
        @extend %fullBlock;
        .date-time {
            float: left;
            font-size: 12px;
            font-weight: 600;
            color: $accent;
            text-transform: uppercase;
            letter-spacing: 0.02em;
            padding-bottom: ($gutter/2);
            border-bottom: 2px solid $accent;
            margin-bottom: 17px;
        }
        h4,
        p {
            @extend %fullBlock;
        }
        h4 {
            font-size: 16px;
            text-transform: uppercase;
            font-weight: 900;
            line-height: 1.313em;
            color: $dark;
            letter-spacing: 0.02em;
            margin-bottom: 15px;
        }
        p {
            color: $dark;
            line-height: 1.2em;
        }
        .article_icon {
            @extend %fullBlock;
            margin-top: 18px;
            height: 21px;
            background-size: 39px 21px;
            background-position: center;
            background-image: url('../images/arrow_up_icon-purple.png');
            background-repeat: no-repeat;
        }
    }
    &:hover {
        .medium_article_thumb {
            &:after {
                opacity: 0;
            }
            img {
                filter: grayscale(0);
            }
        }
    }
}

//DONATIONS
.donations_section {
    .section_headline {
        margin-bottom: 54px;
    }
}

.donations_row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-conent: center;
    .donations-col {
        text-align: center;
        margin-bottom: 40px;
        filter: grayscale(100%);
        transition: $transition1;
        &:hover {
            filter: grayscale(0);
        }
    }
}


/*=====  End of HOME  ======*/


/*=============================
=            ABOUT            =
=============================*/

.about_section {
    margin-bottom: 48px;
}

.about_thumb {
    @extend %fullBlock;
    height: $mainArticleHeight;
    position: relative;
    img {
        @extend %imgCropped;
    }
    &:after {
        content: " ";
        @extend %overlay;
        background: transparentize($accent, .8);
    }
}

.about_content {
    @extend %fullBlock;
    color: $dark2;
    letter-spacing: 0.02em;
    h1 {
        @extend %fullBlock;
        color: $dark2;
        text-transform: uppercase;
        letter-spacing: 0.02em;
        font-weight: 900;
        font-size: 28px;
        position: relative;
        margin-bottom: 37px;
        &:before {
            content: " ";
            position: absolute;
            bottom: -20px;
            left: 0;
            height: 4px;
            width: 79px;
            background: $accent;
        }
    }
    p {
        line-height: 1.2em;
        color: $dark2;
        letter-spacing: 0.02em;
        margin-bottom: 17px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}

//STAFF SECTION
.staff_section {
    margin-bottom: 49px;
}

.staff_item {
    @extend %fullBlock;
    &_thumb {
        @extend %fullBlock;
        height: $staffHeight;
        position: relative;
        margin-bottom: 18px;
        &:after {
            content: " ";
            @extend %overlay;
            background: transparentize($accent, .8);
        }
        img {
            @extend %imgCropped;
        }
    }
    &_content {
        @extend %fullBlock;
        h4 {
            @extend %fullBlock;
            font-size: 16px;
            color: $dark;
            font-weight: 900;
            text-transform: uppercase;
            letter-spacing: 0.02em;
            margin-bottom: 15px;
        }
        h5 {
            color: $dark;
            font-weight: 900;
            letter-spacing: 0.02em;
            margin-bottom: 15px;
        }
        a {
            color: $dark;
            text-decoration: none;
        }
    }
}

//MISION/VISION
.mission_section {
    margin-top: 59px;
}

.mission_content {
    @extend %fullBlock;
    border: 1px solid #d7d7d7;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 36px;
    padding-bottom: 57px;
    h2,
    h3 {
        @extend %fullBlock;
        color: $dark2;
        text-transform: uppercase;
        letter-spacing: 0.02em;
        font-weight: 900;
        font-size: 28px;
        position: relative;
        margin-bottom: 32px;
        &:before {
            content: " ";
            position: absolute;
            bottom: -19px;
            left: 0;
            height: 4px;
            width: 79px;
            background: $accent;
        }
    }
    p {
        line-height: 1.2em;
        color: $dark2;
        letter-spacing: 0.02em;
        margin-bottom: 17px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}

//VALUES
.values_section {
    margin-bottom: 20px;
    .section_headline {
        margin-bottom: 46px;
    }
}

.value-col:nth-child(3n+1) {
    clear: left;
}

.value_item {
    @extend .mission_content;
    margin-bottom: $gutter;
    padding-left: $gutter;
    padding-right: 23px;
    padding-top: ($gutter - 3);
    padding-bottom: 25px;
    h3 {
        font-size: 18px;
        margin-bottom: 16px;
        &:before {
            display: none;
        }
    }
}

.has-border-bottom {
    border-bottom: 1px solid #d7d7d7;
}

//ROLE SECTION
.role_section {
    margin-top: $gutter;
}

.role_item {
    @extend .value_item;
    padding-top: 26px;
    padding-right: 30px;
    h3 {
        font-size: 28px;
        margin-bottom: 24px;
    }
    h4 {
        @extend %fullBlock;
        color: $dark2;
        text-transform: uppercase;
        letter-spacing: 0.02em;
        font-weight: 900;
        position: relative;
        margin-bottom: 21px;
        font-size: 18px;
    }
    ol {
        list-style: upper-alpha;
        font-size: 18px;
        font-weight: 900;
        padding-left: 24px;
        margin-top: 5px;
        margin-bottom: 25px;
        li {
            line-height: 1.167em;
            padding-left: 22px;
            margin-bottom: 20px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    ul {
        @extend %fullBlock;
        li {
            @extend %fullBlock;
            display: flex;
            margin-bottom: 8px;
            &:last-child {
                margin-bottom: 0;
            }
            span {
                padding-left: 10px;
                margin-left: auto;
            }
            h5 {
                font-weight: 900;
                letter-spacing: 0.02em;
                color: $dark;
            }
        }
    }
}


/*=====  End of ABOUT  ======*/


/*=================================
=            PROGRAMET            =
=================================*/

.programet_section {
    margin-bottom: 98px;
}

.prog_row {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
}

.programet_slider {
    @extend %fullBlock;
    background: $accent;
    height: $mainArticleHeight;
    position: relative;
    img {
        @extend %imgCropped;
        height: $mainArticleHeight;
    }
    .owl-nav {
        position: absolute;
        bottom: 27px;
        left: 0;
        width: 100%;
        padding: 0 28px;
        >div {
            width: 42px;
            height: 11px;
            background-repeat: no-repeat;
            background-position: center;
        }
        .owl-prev {
            background-image: url('../images/slider_left.png');
            float: left;
        }
        .owl-next {
            background-image: url('../images/slider_right.png');
            float: right;
        }
    }
}

.programet_content {
    @extend .about_content;
    margin-top: au;
}

.purple_line {
    @extend %fullBlock;
    position: relative;
    z-index: 1;
    height: 4px;
    background: $accent;
}

.program_col_wrap {
    @extend %fullBlock;
    margin-top: -28px;
    margin-bottom: 58px;
    position: relative;
    z-index: 2;
    .container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        counter-reset: prog;
    }
}

.program_col_item {
    float: left;
    width: 20%;
    padding-left: 11px;
    padding-right: 10px;
    .number {
        float: left;
        width: 52px;
        height: 52px;
        border-radius: 50%;
        border: 4px solid $accent;
        display: flex;
        justify-content: center;
        align-items: center;
        background: white;
        color: $accent;
        font-weight: 900;
        margin-bottom: $gutter;
        &:before {
            counter-increment: prog;
            content: counter(prog);
        }
    }
    &_content {
        @extend %fullBlock;
        position: relative;
        padding-top: 17px;
        padding-right: 20px;
        &:before {
            content: " ";
            position: absolute;
            height: 4px;
            width: 80px;
            background: $accent;
            top: 0;
            left: 0;
        }
        p {
            line-height: 1.2;
            color: $dark;
            margin-top: 5px;
        }
    }
}

//Aktivitete 
.aktivitet_col {
    .medium_article {
        margin-bottom: ($gutter*2);
    }
}


/*=====  End of PROGRAMET  ======*/


/*=====================================
=            LAJME ARCHIVE            =
=====================================*/

.select_filter {
    float: right;
    display: flex;
    select {
        width: 140px;
        height: 40px;
        line-height: 38px;
        border: 2px solid $accent;
        float: left;
        font-size: 12px;
        appearance: none;
        padding-left: 18px;
        background: white;
        option {
            font-size: 12px;
            padding-left: 18px;
        }
    }
    .input_group {
        float: left;
        position: relative;
        &:before {
            content: " ";
            background: url('../images/carret_down.png') no-repeat center;
            background-size: 11px 6px;
            width: 11px;
            height: 6px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 10px;
        }
    }
    .input_group:first-child {
        margin-right: $gutter;
    }
}

.lajme_list {
    padding-top: 58px;
}

.news_item {
    @extend %fullBlock;
    padding-bottom: $gutter;
    margin-bottom: $gutter;
    border-bottom: 1px solid #d7d7d7;
    &:hover {
        h3 {
            color: $accent;
        }
    }
    &_date {
        font-size: 16px;
        color: $accent;
        font-weight: 600;
    }
    &_thumb {
        height: $mediumArticleHeight;
        img {
            @extend %imgCropped;
            height: $mediumArticleHeight
        }
    }
    &_content {
        h3 {
            @extend %fullBlock;
            color: $dark2;
            text-transform: uppercase;
            letter-spacing: 0.02em;
            font-weight: 900;
            font-size: 28px;
            position: relative;
            margin-bottom: 19px;
            transition: $transition1;
        }
        .date_time {
            @extend %fullBlock;
            font-size: 12px;
            color: $accent;
            margin-bottom: 5px;
        }
        p {
            line-height: 1.2em;
            color: $dark2;
            letter-spacing: 0.02em;
        }
        .article_icon {
            @extend %fullBlock;
            margin-top: 28px;
            height: 21px;
            background-size: 39px 21px;
            background-position: 0 0;
            background-image: url('../images/arrow_up_icon-purple.png');
            background-repeat: no-repeat;
        }
    }
}

.link_holder {
    @extend %fullBlock;
    text-align: center;
    margin-top: $gutter;
    .load_more {
        display: inline-block;
        width: 220px;
        height: 40px;
        text-align: center;
        line-height: 38px;
        background: $accent;
        color: white;
        font-size: 12px;
        letter-spacing: 0.02em;
        font-weight: 600;
        transition: $transition1;
        border: 2px solid $accent;
        &:hover {
            background: lighten($accent, 10%);
        }
        &.loading {
            background: transparent;
            box-shadow: 0 0 0 rgba(204, 169, 44, 0.4);
            animation: pulse 2s infinite;
            color: $accent;
        }
        &.all-loaded {
            background: transparent;
            box-shadow: 0 0 0 rgba(204, 169, 44, 0.4);
            color: $accent;
            cursor: text;
        }
    }
}

@-webkit-keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4)
    }
    70% {
        box-shadow: 0 0 0 10px rgba(204, 169, 44, 0)
    }
    100% {
        box-shadow: 0 0 0 0 rgba(204, 169, 44, 0)
    }
}


/*=====  End of LAJME ARCHIVE  ======*/


/*====================================
=            SINGLE LAJME            =
====================================*/

.news_article {
    @extend %fullBlock;
    clear: both;
    .container {
        border-bottom: 1px solid #d7d7d7;
        padding-bottom: 60px;
    }
}

.article_thumb {
    @extend %fullBlock;
    height: $mainArticleHeight;
    margin-bottom: ($gutter - 4);
    img {
        @extend %imgCropped;
    }
}

.article_content {
    @extend %fullBlock;
    color: $dark2;
    font-size: 16px;
    h1,
    h2,
    h3,
    h4,
    h5 {
        @extend %fullBlock;
        color: $dark2;
        text-transform: uppercase;
        letter-spacing: 0.02em;
        font-weight: 900;
    }
    h1 {
        font-size: 28px;
        position: relative;
        margin-bottom: 19px;
        transition: $transition1;
    }
    h2 {
        font-size: 24px;
        margin-top: 14px;
        margin-bottom: 20px;
    }
    h3 {
        font-size: 20px;
        margin-top: 14px;
        margin-bottom: 20px;
    }
    h4 {
        font-size: 18px;
        margin-top: 14px;
        margin-bottom: 20px;
    }
    h5 {
        font-size: 16px;
        margin-top: 14px;
        margin-bottom: 20px;
    }
    .date-time {
        @extend %fullBlock;
        font-size: 12px;
        color: $accent;
        margin-bottom: 16px;
    }
    p {
        letter-spacing: 0.02em;
        margin-bottom: 20px;
        line-height: 1.33em;
    }
    blockquote,
    q {
        @extend %fullBlock;
        quotes: "“" "”" "‘" "’";
        position: relative;
        padding-left: 71px;
        padding-right: 71px;
        margin-top: 25px;
        margin-bottom: 32px;
        z-index: -1;
        p {
            font-size: 28px;
            font-weight: bold;
            font-style: italic;
            line-height: 1.23em;
        }
        &:before {
            content: open-quote;
            font-size: 116px;
            color: $accent;
            font-weight: bold;
            font-style: italic;
            float: left;
            position: absolute;
            top: -32px;
            left: -16px;
        }
        &:after {
            content: close-quote;
            font-size: 116px;
            color: $accent;
            font-weight: bold;
            font-style: italic;
            float: right;
            position: absolute;
            bottom: -59px;
            right: 25px;
        }
    }
    ul,
    ol {
        margin-bottom: 20px;
        padding-left: 20px;
        li {
            line-height: 1.2em;
            margin-bottom: 9px;
        }
    }
    ul {
        list-style: disc;
    }
    ol {
        list-style: decimal;
    }
    a {
        color: $accent;
        text-decoration: underline;
    }
    b,
    strong {
        font-weight: 900 !important;
    }
    iframe,
    figure,
    .fb_iframe_widget {
        max-width: 100% !important;
        margin-bottom: 20px !important;
    }
    .fb_iframe_widget span {
        width: 100%;
    }
    figcaption {
        font-size: 12px;
        text-transform: uppercase;
        font-weight: 700;
        color: $dark;
        margin-top: 4px;
    }
}

.share_article {
    float: left;
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 61px;
    span {
        float: left;
        font-size: 12px;
        font-weight: 600;
        margin-right: 22px;
    }
    ul {
        padding-left: 0;
        list-style: none;
        margin-bottom: 0;
        li {
            float: left;
            margin-right: 13px;
            line-height: 100%;
            margin-bottom: 0;
            a {
                width: 40px;
                height: 40px;
                border: 2px solid $accent;
                font-size: 22px;
                color: $accent;
                text-align: center;
                line-height: 38px;
                float: left;
                transition: $transition1;
                &:hover {
                    background: $accent;
                    color: white;
                }
            }
        }
    }
}

.search_title {
    @extend %fullBlock;
    font-size: 18px;
    color: $dark;
    text-transform: uppercase;
    font-weight: 900;
    span {
        text-transform: none;
        color: $accent;
    }
}

.no-results {
    @extend %fullBlock;
    margin-top: 30px;
    .page-title {
        font-weight: 900;
        text-transform: uppercase;
        margin-bottom: 20px;
        font-size: 16px;
    }
    p {
        font-size: 14px;
        line-height: 1.33em;
        margin-bottom: 20px;
    }
}

.search-form {
    @extend .main_search;
    position: relative;
    input[type="search"] {
        appearance: none;
    }
    input[type="submit"] {
        text-indent: -999999px;
        overflow: hidden;
    }
}

.error-404 {
    h2 {
        @extend %fullBlock;
        font-size: 18px;
        color: $dark;
        text-transform: uppercase;
        font-weight: 900;
        margin-bottom: 20px;
        margin-top: 10px;
    }
    ul {
        padding-left: 0;
        list-style: none !important;
        margin-bottom: 20px;
        li {
            margin-bottom: 10px;
            a {
                text-decoration: none;
            }
        }
    }
}


/*=====  End of SINGLE LAJME  ======*/


/*===============================
=            CONTACT            =
===============================*/

.contact_section {
    margin-bottom: 49px;
    margin-top: 30px;
}

.contact_item {
    @extend %fullBlock;
    border: 1px solid #d7d7d7;
    padding-left: 43px;
    padding-right: 39px;
    padding-top: 27px;
    padding-bottom: 30px;
    h2 {
        font-size: 16px;
        color: $dark;
        text-transform: uppercase;
        font-weight: 900;
        letter-spacing: 0, 02em;
        position: relative;
        float: left;
        &:before {
            content: " ";
            position: absolute;
            bottom: -21px;
            left: 0;
            height: 4px;
            width: 52px;
            background: $accent;
        }
    }
    p {
        margin-top: -4px;
        float: right;
        font-size: 16px;
        color: $dark;
        line-height: 1.5em;
    }
}

//Form
.contact_form {
    @extend %fullBlock;
    br {
        display: none !important;
    }
    .input_group span {
        width: 100%;
        padding-top: 0;
        margin-top: 0;
    }
    .input_group {
        @extend %fullBlock;
        margin-bottom: $gutter;
    }
    input {
        @extend %fullBlock;
        height: 40px;
        border: 2px solid $accent;
        line-height: 38px;
        font-size: 12px;
        padding-left: 18px;
        padding-right: 18px;
        font-weight: 600;
        @include placeholder {
            font-size: 12px;
            font-weight: 600;
        }
    }
    textarea {
        @extend %fullBlock;
        height: 198px;
        border: 2px solid $accent;
        font-size: 12px;
        padding-left: 18px;
        padding-right: 18px;
        font-weight: 600;
        padding-top: 10px;
        @include placeholder {
            font-size: 12px;
            font-weight: 600;
        }
    }
    span {
        float: left;
        font-size: 16px;
        padding-top: 7px;
    }
    input[type="submit"] {
        float: right;
        color: white;
        background: $accent;
        padding-top: 0;
        padding-bottom: 0;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0.02em;
        cursor: pointer;
        max-width: 262px;
        transition: $transition1;
        &:hover {
            background: lighten($accent, 10%);
        }
    }
}

.wpcf7-response-output {
    @extend %fullBlock;
    text-align: center;
    text-transform: uppercase;
    font-size: 12px;
    border: 0 !important;
    padding: 1em !important;
    font-weight: 900;
    letter-spacing: 0.02em;
    color: white;
    margin-left: 0 !important;
    margin-right: 0 !important;
}

div.wpcf7-validation-errors {
    background: #f7e700;
}

span.wpcf7-not-valid-tip {
    color: #f00;
    font-size: 12px;
    display: block;
    text-transform: uppercase;
    position: absolute;
    bottom: -17px;
    font-weight: 700;
}

div.wpcf7-mail-sent-ok {
    background: #398f14;
}


/*=====  End of CONTACT  ======*/


/*==================================
=            PUBLIKIMET            =
==================================*/

.pub_filters {
    @extend %fullBlock;
    padding-bottom: $gutter;
    border-bottom: 1px solid #d7d7d7;
    margin-bottom: $gutter;
}

.publikimet_section {
    .container {
        border-bottom: 1px solid #d7d7d7;
    }
}

.radio_group {
    float: left;
    margin-top: 11px;
    input {
        display: none;
    }
}

.hidden_sub {
    display: none;
}

#infscr-loading {
    display: none !important;
}

.custom_radio_wrap {
    float: left;
    font-size: 16px;
    color: #d1d1d1;
    font-weight: 900;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    &:before {
        content: " ";
        width: 33px;
        height: 3px;
        background: $accent;
        float: right;
        margin: 0 20px;
        transform: rotate(-45deg);
        top: 8px;
        position: relative;
    }
    &:last-child {
        &:before {
            display: none;
        }
    }
    label {
        cursor: pointer;
        transition: $transition1;
        &:hover {
            color: $accent;
        }
    }
    input {
        width: 0;
        height: 0;
        overflow: hidden;
        visibility: visible;
        position: absolute;
        z-index: -30;
        &:checked {
            ~label {
                color: $dark;
            }
        }
    }
}

.publikim_item {
    @extend %fullBlock;
    height: 228px;
    background-image: -moz-linear-gradient( 80deg, rgb(64, 68, 133) 0%, rgb(48, 141, 227) 100%);
    background-image: -webkit-linear-gradient( 80deg, rgb(64, 68, 133) 0%, rgb(48, 141, 227) 100%);
    background-image: -ms-linear-gradient( 80deg, rgb(64, 68, 133) 0%, rgb(48, 141, 227) 100%);
    background-color: #3f488a;
    padding: $gutter;
    padding-bottom: 25px;
    padding-right: 20px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $gutter;
    transition: $transition1;
    &:hover {
        opacity: .8;
    }
    img {
        width: auto;
        height: auto;
        flex-shrink: 3;
        max-width: 61px;
        max-height: 61px;
    }
    h3 {
        @extend %fullBlock;
        margin-top: auto;
        color: white;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 900;
        letter-spacing: 0.02em;
        line-height: 1.313em;
        flex-shrink: 0;
        flex-grow: 2;
        max-height: 104px;
        overflow: hidden;
        transition: $transition1;
    }
}


/*=====  End of PUBLIKIMET  ======*/
