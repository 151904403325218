//Maiheader
.main_header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: white;
    z-index: 2222;
    transition: $transition1;
    &.has-scrolled {
        transform: translateY(-38px)
    }
}

//Top Header
.top_header {
    padding: 11px;
    background-image: -moz-linear-gradient( 4deg, rgb(64, 68, 133) 0%, rgb(91, 97, 189) 100%);
    background-image: -webkit-linear-gradient( 4deg, rgb(64, 68, 133) 0%, rgb(91, 97, 189) 100%);
    background-image: -ms-linear-gradient( 4deg, rgb(64, 68, 133) 0%, rgb(91, 97, 189) 100%);
    background-color: rgb(64, 68, 133);
}

//Top header left
.top_header_left {
    float: left;
    font-size: 12px;
    ul {
        margin-top: 3px;
        color: #fff;
        float: left;
    }
    a {
        color: white;
    }
    li {
        float: left;
        &:after {
            content: "/";
            float: right;
            margin: 0 7px;
        }
        &:last-child {
            &:after {
                display: none;
            }
        }
    }
}

//Top Header Right
.top_header_right {
    float: right;
    .language {
        float: left;
        font-size: 12px;
        margin-top: 3px;
        li {
            float: left;
            margin-right: 20px;
            a {
                color: #fff;
                text-decoration: none;
                transition: $transition1;
                opacity: .3;
                &:hover {
                    opacity: 1;
                }
            }
            &.active {
                a {
                    opacity: 1;
                    text-decoration: underline;
                }
            }
        }
    }
}

//Top Header Social
.top_header_social {
    float: right;
    margin-left: 20px;
    font-size: 16px;
    li {
        float: left;
        margin-right: 20px;
        a {
            color: #fff;
            float: left;
        }
        &:last-child {
            margin-right: 0;
        }
    }
}

.bottom_header {
    float: left;
    width: 100%;
    height: 108px;
    border-bottom: 1px solid #c5c7da;
    padding-top: 24px;
}

.logo {
    float: left;
}

.menu-item-has-children {
    &:after {
        content: " ";
        position: absolute;
        width: 11px;
        height: 6px;
        left: 50%;
        transform: translateX(-50%);
        bottom: 26px;
        background: url('../images/down_arrow.png');
    }
}

.main_menu {
    float: left;
    margin-left: 120px;
    margin-top: 22px;
    nav>ul>li {
        float: left;
        margin-right: 40px;
        padding-bottom: 46px;
        position: relative;
        border-bottom: 2px solid transparent;
        &:hover {
            >.sub-menu {
                visibility: visible;
                /* shows sub-menu */
                opacity: 1;
                transform: translateY(0%);
                transition-delay: 0s, 0s, 0.3s;
            }
        }
        >a {
            font-weight: 900;
            transition: $transition1;
            color: #2a2a2a;
            font-size: 15px;
            cursor:pointer;
            &:hover {
                color: $accentLight;
            }
        }
        &.current-menu-item {
            border-color: $accent;
            >a {
                color: $accentLight;
            }
        }
    }
}

.sub-menu {
    visibility: hidden;
    opacity: 0;
    transform: translateY(-10px);
    transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;
    z-index: 5;
    position: absolute;
    top: 61px;
    left: -62px;
    width: 221px;
    background: $accentLight;
    font-size: 13px;
    li {
        @extend %fullBlock;
        border-bottom: 1px solid #fff;
        &.current-menu-item {
            a{
                opacity: 0.8;
            }
        }
        &:last-child {
            border-bottom: 0;
        }
    }
    a {
        @extend %fullBlock;
        padding-top: 10px;
        padding-bottom: 13px;
        font-weight: 900;
        color: white;
        text-align: center;

    }
}

//Header Search
.header_search {
    float: right;
    margin-top: 9px;
    width: 208px;
    position: relative;
    input[type="submit"] {
        background-color: transparent;
        position: absolute;
        top: 0;
        right: 0;
        width: 40px;
        height: 40px;
        background-image: url('../images/search-icon.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 18px 17px;
        border: 0;
        cursor: pointer;
        &:focus {
            outline: 0;
        }
    }
    input[type="text"],
    input[type="search"] {
        @extend %fullBlock;
        height: 40px;
        line-height: 40px;
        border: 1px solid #d7d7d7;
        font-size: 12px;
        color: $dark;
        font-weight: 500;
        padding-left: 14px;
        padding-right: 40px;
        &:focus {
            border-color: $dark;
        }
        @include placeholder {
            color: #acacac;
            font-weight: 500;
        }
    }
}

//General Search
.main_search {
    @extend %fullBlock;
    margin: $gutter 0;
    .input_holder {
        @extend %fullBlock;
        position: relative;
    }
    input[type="submit"] {
        background-color: transparent;
        position: absolute;
        top: 0;
        right: 0;
        width: 40px;
        height: 40px;
        background-image: url('../images/search-icon.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 18px 17px;
        border: 0;
        cursor: pointer;
        &:focus {
            outline: 0;
        }
    }
    input[type="text"],
    input[type="search"] {
        @extend %fullBlock;
        height: 40px;
        line-height: 40px;
        border: 1px solid #d7d7d7;
        font-size: 12px;
        color: $dark;
        font-weight: 500;
        padding-left: 14px;
        padding-right: 40px;
        &:focus {
            border-color: $dark;
        }
        @include placeholder {
            color: #acacac;
            font-weight: 500;
        }
    }
}
